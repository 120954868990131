import { useMutation } from "react-query"

import {
    AskResponse,
    ChatRequest,
    getConversation,
    ModelVersions,
    removeQAPair,
    SearchApproaches,
    setConversation,
    stopGeneration,
    togglePinConversation,
} from "@/api"

import { useDispatch, useSelector } from "react-redux"
import {
    setActiveAnalysisPanelTab,
    setAnswer,
    setAnswerStream,
    setChatError,
    setChatId,
    setChatIsPinned,
    setChatLoading,
    setChatTitle,
    setIsStreamStopped,
    setLastQuestionRef,
    setSelectedDocuments,
} from "@/slices/chatSlice"
import { generatePath, useMatch, useNavigate } from "react-router-dom"
import { RoutesPath } from "@/router/config"
import { toast } from "react-toastify"
import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import useDispatchCharByChar from "./useCharacterDispatch"

export const useDialog = () => {
    const cacheKey = "dialog"
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const match = useMatch({
        path: RoutesPath.ChatId,
    })
    const answerGenerationPromptTemplate = useSelector((state: any) => state.chat.answerGenerationPromptTemplate)
    const cognitiveSearchQueryGenerationPromptTemplate = useSelector(
        (state: any) => state.chat.cognitiveSearchQueryGenerationPromptTemplate
    )

    const useSuggestFollowupQuestions = useSelector((state: any) => state.chat.useSuggestFollowupQuestions)
    const preselectContextFilesMode = useSelector((state: any) => state.chat.preselectContextFilesMode)
    const docsPreselectSearchThreshold = useSelector((state: any) => state.chat.docsPreselectSearchThreshold)
    const selectedSearchApproach = useSelector((state: any) => state.chat.searchApproach)
    const files = useSelector((state: any) => state.chat.selectedDocs)
    const chatId = useSelector((state: any) => state.chat.chatId)
    const error = useSelector((state: any) => state.chat.chatError)
    const temperature = useSelector((state: any) => state.chat.temperatureCount)
    const modelVersion = useSelector((state: any) => state.chat.model)
    const assistantType = useSelector((state: any) => state.chat.assistantType)
    const answers = useSelector((state: any) => state.chat.answers)
    const modelProvider = useSelector((state: any) => state.chat.modelProvider)
    const isChatGPT4 = modelVersion === ModelVersions.GPT4
    const selectedTokensUsageMode = useSelector((state: any) => state.chat.selectedTokensUsageMode)
    const isLoading = useSelector((state: any) => state.chat.isLoading)
    const chatIsPinned = useSelector((state: any) => state.chat.chatIsPinned)

    const [streamBuffer, setStreamBuffer] = useState("")

    useDispatchCharByChar(streamBuffer, isLoading, isChatGPT4)

    useEffect(() => {
        if (!isLoading) {
            setStreamBuffer("")
        }
    }, [isLoading])

    const onStreamingProgress = useCallback(
        (progressEvent: { chat_id: any; answer: any }) => {
            // console.log("onStreamingProgress called at", new Date().toISOString())
            if (progressEvent.chat_id !== chatId) {
                dispatch(setChatId(progressEvent.chat_id))
            }

            if (progressEvent.answer) {
                const blocksToReplace = ["mermaid", "```"]
                const filteredAnswer = blocksToReplace.reduce(
                    (answer, block) => answer.replace(new RegExp(block, "g"), ""),
                    progressEvent.answer
                )

                setStreamBuffer(prevBuffer => {
                    return `${prevBuffer ?? ""}${filteredAnswer}`.replace(/\[.*?],* *\.*/g, "")
                })
            } else if (progressEvent.answer === null) {
                setStreamBuffer("")
            }
        },
        [chatId, dispatch]
    )

    const onStreamingEnd = useCallback(() => {
        setTimeout(() => {
            dispatch(setAnswerStream(null))
        }, 2000)
    }, [dispatch])

    const setRequestParams = async (question: string, newChatId?: string | undefined) => {
        const request: ChatRequest = {
            user_question: question.trim(),
            overrides: {
                answer_generation_prompt_template:
                    answerGenerationPromptTemplate.length === 0 ? undefined : answerGenerationPromptTemplate,
                cognitive_search_query_generation_prompt_template:
                    cognitiveSearchQueryGenerationPromptTemplate.length === 0
                        ? undefined
                        : cognitiveSearchQueryGenerationPromptTemplate,
                suggest_followup_questions: useSuggestFollowupQuestions,
                preselect_context_files_mode: preselectContextFilesMode,
                docs_preselect_search_threshold: docsPreselectSearchThreshold,
                isChatGPT4: isChatGPT4,
                tokens_usage_mode: selectedTokensUsageMode,
                temperature: temperature,
                assistant_type: assistantType,
                search_approach: selectedSearchApproach as SearchApproaches,
                model_provider: modelProvider,
            },
            should_be_pinned: chatIsPinned ?? false,
            context_files_id: files?.filter((id: string) => /^[a-z\d]{24}$/.test(id)) ?? [],
        }

        if (newChatId || newChatId === "") {
            if (newChatId !== "") {
                request.chat_id = newChatId ?? undefined
            }
        } else {
            request.chat_id = chatId ? chatId : undefined
        }
        return await setConversation(dispatch, request, onStreamingProgress, onStreamingEnd)
    }

    const sendMessage = useMutation(
        cacheKey,
        ({ question, newChatId }: { question: string; newChatId?: string | undefined }) => {
            error && dispatch(setChatError(""))
            dispatch(setAnswerStream(null))
            dispatch(setLastQuestionRef(question))
            dispatch(setChatLoading(true))
            dispatch(setActiveAnalysisPanelTab(""))

            if (!chatId || !match?.params.id) {
                navigation(generatePath(RoutesPath.Chat))
            }
            dispatch(setIsStreamStopped(false))
            return setRequestParams(question, newChatId)
        },
        {
            onSuccess: (data: any) => {
                if (data) {
                    const id = data._id ?? data.chat_id
                    // console.log("Received data in sendMessage onSuccess callback", data)
                    setTimeout(() => {
                        receiveMessages.mutate({ id: id || chatId })
                    }, 100)
                    setTimeout(() => {
                        receiveMessages.mutate({ id: id || chatId })
                    }, 600)
                } else {
                    // console.error("Received NULL data in sendMessage onSuccess callback")
                    setTimeout(() => {
                        receiveMessages.mutate({ id: chatId })
                    }, 100)
                    setTimeout(() => {
                        receiveMessages.mutate({ id: chatId })
                    }, 600)
                }
            },
            onError: (error: any) => {
                if (error instanceof Error) {
                    dispatch(setChatError(error.message))
                } else {
                    dispatch(setChatError(String(error)))
                }

                dispatch(setChatLoading(false))
            },
            onSettled: () => {},
        }
    )

    const receiveMessages = useMutation(
        cacheKey,
        ({ id, getSelectedDocuments }: { id: string; getSelectedDocuments?: boolean }) => {
            if (id !== chatId) {
                dispatch(setChatId(id))
                dispatch(setAnswer([]))
                dispatch(setChatTitle([]))
                dispatch(setChatIsPinned(false))
            }

            navigation(generatePath(RoutesPath.ChatId, { id: id }))

            // default to false
            let shouldGetSelectedDocuments = getSelectedDocuments !== undefined ? getSelectedDocuments : false

            if (!files.length) {
                // if no files are selected, get the selected documents
                shouldGetSelectedDocuments = true
            }

            return getConversation(id).then((data: AskResponse) => {
                if (shouldGetSelectedDocuments) {
                    const lastBotHistory = data.history?.[data.history.length - 1]?.bot?.context_files_id ?? []
                    dispatch(setSelectedDocuments(lastBotHistory))
                }
                return data
            })
        },
        {
            onSuccess: (data: AskResponse) => {
                dispatch(setAnswer(data.history))
                dispatch(setChatTitle(data.title))
                dispatch(setChatIsPinned(data.is_pin))
            },
            onSettled: () => {
                dispatch(setChatLoading(false))
            },
        }
    )
    const removeMessage = useMutation(
        ({
            chatId,
            index,
            history_id,
            questionText,
            remove_conversation_if_first = true,
            refresh_conversation = true, // do not remove this line as it is used in the onSuccess callback
        }: {
            chatId: string
            index: number
            history_id: string | undefined
            questionText: string
            remove_conversation_if_first?: boolean
            refresh_conversation?: boolean
        }) => removeQAPair(chatId, index, history_id, questionText, remove_conversation_if_first),
        {
            onMutate: async variables => {
                const previousAnswers = answers

                // Optimistically update the answers
                const updatedHistory = previousAnswers.filter(
                    (item: any, itemIndex: number) => itemIndex !== variables.index
                )
                dispatch(setAnswer(updatedHistory))

                return { previousAnswers, updatedHistory }
            },
            onSuccess: (data, variables, context) => {
                if (variables.refresh_conversation) {
                    receiveMessages.mutate({ id: variables.chatId })
                }
                // toast.success("Q&A removed successfully.")
            },
            onError: (error, variables, context) => {
                // If the mutation fails, rollback to the previous answers
                if (context?.previousAnswers) {
                    dispatch(setAnswer(context.previousAnswers))
                }

                if (error instanceof Error) {
                    toast.error(`Error: ${error.message}`)
                } else {
                    toast.error("An unexpected error occurred")
                }
            },
        }
    )

    const stopAnswerGeneration = useMutation((chatId: string) => stopGeneration(chatId), {
        onError: (error: any) => {
            if (error instanceof Error) {
                toast.error(`Error: ${error.message}`)
            } else {
                toast.error("An unexpected error occurred during stopping answer generation")
            }
        },
        onSuccess: () => {
            dispatch(setIsStreamStopped(true))
        },
    })

    const togglePin = useMutation((chatId: string) => togglePinConversation(chatId), {
        onError: (error: any) => {
            if (error instanceof Error) {
                toast.error(`Error: ${error.message}`)
            } else {
                toast.error("An unexpected error occurred during pinning")
            }
        },
    })

    return {
        isLoading,
        sendMessage,
        receiveMessages,
        removeMessage,
        togglePin,
        stopAnswerGeneration,
    }
}

const usePinnedConversations = () => {
    const [pinnedConversations, setPinnedConversations] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchPinnedConversations = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get("/chat/pinned")
                setPinnedConversations(response.data)
            } catch (error) {
                console.error("Error fetching pinned conversations:", error)
            }
            setIsLoading(false)
        }

        fetchPinnedConversations()
    }, [])

    return { pinnedConversations, isLoading }
}

export default usePinnedConversations
