export const enum SearchApproaches {
    Auto = "auto",
    Text = "text",
    Keyword = "keyword",
    Aggregator = "aggregator",
}

export const enum ModelVersions {
    GPT4 = "gpt4",
    GPT4_MINI = "gpt4-mini",
}

export const enum TokensUsageModes {
    Standard = "standard",
    Increased = "increased",
}

export const enum ImproveSearchQueryModes {
    Always = "Always",
    Manual = "Manual",
    Disabled = "Disabled",
}

export const enum PreselectContextFilesModes {
    Enabled = "Enabled",
    Disabled = "Disabled",
}

export const enum AssistantTypes {
    BASIC = "basic",
    DOC_ASSISTANT = "doc_assistant",
}

export type AskRequestOverrides = {
    temperature?: number
    answer_generation_prompt_template?: string
    cognitive_search_query_generation_prompt_template?: string
    suggest_followup_questions?: boolean
    preselect_context_files_mode?: PreselectContextFilesModes
    isChatGPT4?: boolean
    assistant_type?: AssistantTypes
    search_approach: SearchApproaches
    docs_preselect_search_threshold?: number
    model_provider: string
    tokens_usage_mode: TokensUsageModes
}

export type History = {
    user: string
    bot: {
        answer: string
        data_points?: string[]
        thoughts?: string
        context_files_id?: string[]
        was_interrupted: boolean
        tokens_used?: {
            prompt_tokens?: number
            completion_tokens?: number
            total_tokens?: number
        }
        settings?: {
            search_approach?: SearchApproaches
            overrides?: AskRequestOverrides
            context_files_id?: string[]
            tokens_used?: number
        }
    }
    created_time_utc: any
}

export type AskResponse = {
    cited_figures: any
    was_interrupted: boolean
    title: string
    answer: string
    thoughts: any
    data_points: string[]
    is_pin: boolean
    history: History[]
    detail?: string
    tokens_used: {
        prompt_tokens: number
        completion_tokens: number
        total_tokens: number
    }
    _id?: string
    chat_id?: string
    execution_time?: number
    settings: any
}

export type ChatRequest = {
    user_question: string
    overrides?: AskRequestOverrides
    context_files_id: string[]
    chat_id?: string
    should_be_pinned?: boolean
}
